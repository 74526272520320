import Vue from 'vue';
import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice'
import Tooltip from 'primevue/tooltip'
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import InputSwitch from 'primevue/inputswitch'
import Dropdown from 'primevue/dropdown'
import Breadcrumb from 'primevue/breadcrumb'
import Calendar from 'primevue/calendar'
import Checkbox from 'primevue/checkbox'
import Chips from 'primevue/chips'
import Toast from 'primevue/toast'
import Paginator from 'primevue/paginator'
import Toolbar from 'primevue/toolbar'
import Password from 'primevue/password'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Card from 'primevue/card'
import Tag from 'primevue/tag';
import Badge from 'primevue/badge';
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import Skeleton from 'primevue/skeleton'
import Multiselect from 'primevue/multiselect'
import FileUpload from 'primevue/fileupload'
import ProgressBar from 'primevue/progressbar';
import Message from 'primevue/message';

Vue.use(PrimeVue, {
  ripple: true
})

Vue.use(ToastService)

Vue.directive('tooltip', Tooltip)

const components = {
  Dialog, Button, InputText, Password,
  DataTable, Column, Card, Tag,
  Badge, InputSwitch, Dropdown, Breadcrumb,
  Calendar, Checkbox, Chips, Toast,
  Paginator, Toolbar, Accordion, AccordionTab,
  Skeleton, Multiselect, FileUpload, ProgressBar,
  Message
}

Object.entries(components).forEach(([name, component]) => {
  Vue.component(name, component)
})
